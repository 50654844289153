




































































import { Component, Vue } from "vue-property-decorator";
import { FundUserDto, NotifyTemplateCreateOrUpdateDto } from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import moment from "moment";

@Component({
  name: "fundUserDetail",
  components: {
    ExportWord,
  },
  filters: {
    formatType(type?: string) {
      switch (type) {
        case "ProjectAssessmentPlan":
          return "评估计划通知模板";

        case "OrganizationInvestigation":
          return "尽职调查通知模板";
      }
    },
  },
})
export default class FundUserDetail extends Vue {
  detail: FundUserDto = {};
  typeList: any = [];
  styles = "";
  selector = "table-detail-world";
  filename = "基金成员详情";

  created() {
    if (this.$route.params.id) {
      this.fetchEnumType();
      this.fetchDetail();
    }
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "NotifyTemplateType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  // 获取详情
  async fetchDetail() {
    api.fundUser.get({ id: Number(this.$route.params.id) }).then((res) => {
      this.detail = { ...res };
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
